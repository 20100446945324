import { createRouter, createWebHistory } from 'vue-router';
import Cookies from 'js-cookie';

const PageView = () => import('@/views/PageView.vue');

const FaqsPage = () => import('@/views/FaqsPage.vue');
const ResourcesNews = () => import('@/views/ResourcesNews.vue');
const ResourceDetail = () => import('@/views/ResourceDetail.vue');
const LotListPage = () => import('@/views/LotListpage.vue');
const LotDetailPage = () => import('@/views/LotDetailPage.vue');

// Auction Pages
const PastAuction = () => import('@/views/PastAuction.vue');
const LiveAuction = () => import('@/views/LiveAuction.vue');
const SearchAuctionPage = () => import('@/views/SearchAuctionPage.vue'); // Import SearchAuctionPage component


// Authentication Pages
const RegisterUser = () => import('@/views/RegisterUser.vue');
const ForgotPassword = () => import('@/views/ForgotPassword.vue');
const ConformPassword = () => import('@/views/Reset-password.vue');
const LoginUser = () => import('@/views/LoginUser.vue'); // Import LoginUser component

// User Dashboard Pages
const UserDashboardSidebar = () => import('@/components/UserDashboardSidebar.vue');
const DashboardPage = () => import('@/views/userdashboard/DashboardPage.vue');
const UserProfile = () => import('@/views/userdashboard/UserProfile.vue');
const UserAddress = () => import('@/views/userdashboard/UserAddress.vue');
const NotificationSetting = () => import('@/views/userdashboard/NotificationSetting.vue');
const WishList = () => import('@/views/userdashboard/WishList.vue');
const RecentBids = () => import('@/views/userdashboard/RecentBids.vue');
const MyLots = () => import('@/views/userdashboard/MyLots.vue');
const PreviousOrders = () => import('@/views/userdashboard/PreviousOrders.vue');
const OrderDetail = () => import('@/views/userdashboard/OrderDetail.vue');

// Shopping Pages
const ShoppingCart = () => import('@/views/ShoppingCart.vue');
const CheckOutPage = () => import('@/views/CheckOutPage.vue');
const ReviewOrderPage = () => import('@/views/ReviewOrderPage.vue');
const PaymentSuccess = () => import('@/views/PaymentSuccess.vue');
const PaymentScreen = () => import('@/views/PaymentScreen.vue');
const CartInit = () => import('@/views/CartInit.vue'); // Import CartInit component
const InsurancePage = () => import('@/views/InsurancePage.vue');
const PaymentByBank = () => import('@/views/PaymentByBank.vue');

// Other Pages
const NotFoundPage = () => import('@/views/NotFoundPage.vue'); // 404 Page

// Define routes
const routes = [

  { path: '/live-auction/:slug', name: 'LiveLotListPage', component: LotListPage },
  { path: '/past-auction/:slug', name: 'PastLotListPage', component: LotListPage },
  
  { path: '/lot/:slug(.*)', name: 'LotDetailPage', component: LotDetailPage },
  { path: '/search', name: 'SearchAuctionPage', component: SearchAuctionPage }, // Update SearchAuctionPage route
 


  // Public Pages

  { path: '/faqs', name: 'FaqsPage', component: FaqsPage },
  {
    path: '/resources',
    name: 'resources',
    component: ResourcesNews,
  },
  
    {
      path: '/', // Route for the homepage
      name: 'HomePage',
      component: PageView, // Use the same component
      props: { slug: 'home' } // Pass a default slug for the homepage
    },
    {
      path: '/:slug', // Dynamic route for any other alias
      name: 'PageView',
      component: PageView,
      props: true // Pass the slug as a prop to the PageView component
    },
  
  
  {
    path: '/resource/:slug',  // Only use slug in the path
    name: 'resourceDetail',
    component: ResourceDetail,
    props: true,  // Only pass the slug as a prop to the component
  },
 
 
  // Auction Pages
  { path: '/live-auction', name: 'LiveAuction', component: LiveAuction },

  { path: '/past-auction', name: 'PastAuction', component: PastAuction },
 
  // Authentication Pages
  { path: '/register', name: 'RegisterUser', component: RegisterUser , meta: { guest: true }},
  { path: '/forgot-password', name: 'ForgotPassword', component: ForgotPassword, meta: { guest: true } },
  { path: '/password/reset', name: 'ConformPassword', component: ConformPassword, meta: { guest: true, requiresToken: true } },
  
  { path: '/login', name: 'LoginUser', component: LoginUser, meta: { guest: true } }, // Add LoginUser route

  // Shopping Pages
  { path: '/cart', name: 'ShoppingCart', component: ShoppingCart, meta: { requiresAuth: true } },
  {
    path: '/cart/address',
    name: 'CheckOutPage',
    component: CheckOutPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/cart/insurance',
    name: 'InsurancePage',
    component: InsurancePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/cart/address/review-order',
    name: 'ReviewOrderPage',
    component: ReviewOrderPage,
    meta: { requiresAuth: true },
  },
  { path: '/payment-success', name: 'PaymentSuccess', component: PaymentSuccess },
  {
    path: '/payment',
    name: 'PaymentScreen',
    component: PaymentScreen
  },
  { path: '/cart-init', name: 'CartInit', component: CartInit }, // Add CartInit route
  {
    path: '/payment-bank',
    name: 'PaymentByBank',
    component: PaymentByBank,
    meta: { requiresAuth: true },
  },

  // Dashboard Pages
  {
    path: '/dashboard',
    name: 'DashboardPage',
    components: {
      default: DashboardPage,
      sidebar: UserDashboardSidebar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/address',
    name: 'UserAddress',
    components: {
      default: UserAddress,
      sidebar: UserDashboardSidebar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/user-notification',
    name: 'NotificationSetting',
    components: {
      default: NotificationSetting,
      sidebar: UserDashboardSidebar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    components: {
      default: UserProfile,
      sidebar: UserDashboardSidebar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/wishlist',
    name: 'WishList',
    components: {
      default: WishList,
      sidebar: UserDashboardSidebar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/recent-bids',
    name: 'RecentBids',
    components: {
      default: RecentBids,
      sidebar: UserDashboardSidebar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/my-lots',
    name: 'MyLots',
    components: {
      default: MyLots,
      sidebar: UserDashboardSidebar,
    },
    meta: { requiresAuth: true },
  },
  {
    path: '/orders',
    name: 'PreviousOrders',
    components: {
      default: PreviousOrders,
      sidebar: UserDashboardSidebar,
    },
    meta: { requiresAuth: true },
  },
    
  
  { path: '/order-detail/:id', name: 'OrderDetail', component: OrderDetail },
  // 404 Not Found
  { path: '/:catchAll(.*)', name: 'NotFound', component: NotFoundPage },
];

// Create router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0, behavior: 'smooth' });
      }, 200);
    });
  },
});

// Navigation Guards
router.beforeEach((to, from, next) => {
  const token = Cookies.get('token');  // Get token from cookies
  const errorMessageContainer = document.querySelector('.error-message-container');

  // If route requires authentication and no token is found
  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    if (errorMessageContainer) {
      errorMessageContainer.style.display = 'block';
    }
    return next({ name: 'LoginUser' });  // Redirect to login if not authenticated
  }

  // If route is for guests and a token is found (i.e., user is already logged in)
  if (to.matched.some((record) => record.meta.guest) && token) {
    return next({ name: 'DashboardPage' });  // Redirect to Dashboard if logged in
  }

  // If route requires a token in the URL
  if (to.matched.some((record) => record.meta.requiresToken)) {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');
    if (!urlToken) {
      return next({ name: 'NotFound' });  // Redirect to 404 if no token in URL
    }
  }

  // Custom navigation guards
  if (to.name === 'CheckOutPage' && !Cookies.get('cart_items')) {
    return next({ name: 'ShoppingCart' });  // Redirect to cart if no items in cart
  }

  if (to.name === 'PaymentScreen' && from.name !== 'ReviewOrderPage') {
    return next({ name: 'ReviewOrderPage' });  // Redirect to review order if not coming from review order page
  }

  // Proceed to next route
  next();
});

export default router;
