<template>
    <div v-if="!loading">
    <!-- Background Overlay for Search (Top Half) -->
    <transition name="fade">
      <div v-if="searchVisible" class="fixed top-0 left-0 right-0 h-full bg-white z-40"
        @click="closeSearch"></div>
    </transition>

    <!-- Search Component (Triggered by Search Icon) -->
    <component v-if="SearchBar" :is="SearchBar" ref="searchBar" :isVisible="searchVisible" :closeSearch="closeSearch" :searchQuery="searchQuery" />

    <div class="bg-[#f9f5f6] py-2" style="min-height: 44px; position: relative; z-index: 18;">
      <div class="container mx-auto xl:px-4 lg:px-12 md:px-16 sm:px-5 px-4">
        <div class="flex justify-center items-center text-center">
          <p class="text-[12px] sm:text-base lg:text-lg mtext-lg nunito-sans-light hover:text-black transition-colors duration-300 inline-flex items-start">
            <img v-if="menuData.fetchedNotificationMessage" src="@/assets/Notification.svg" alt="Notification Icon" width="12" height="14"
              class="sm:w-3.5 w-3 sm:mt-1.5 sm:mr-2 mr-1 flex-shrink-0 mt-0.5" />
            <span class="flex-1">
              {{ displayMessage }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <!-- Loading Spinner or Placeholder -->
    <div class="inset-0 min-h-[70px] md:min-h-[110px] flex justify-center z-50">
     
    </div>
  </div>
</template>
<script>
import { fetchMenuData } from '@/store';
import { defineAsyncComponent } from 'vue';

export default {
  name: 'SiteNavbar',
  data() {
    return {
      menuOpen: false,
      hoveredIcon: null,
      searchVisible: false,
      activeButton: 'live',
      selectedOption: 'live',
      searchQuery: '',
      hasLiveAuction: false,
      timer: null,
      countdown: {
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      SearchBar: null,
      isSticky: false,
      menuData: null,
      loading: true,
    };
  },
  computed: {
    displayMessage() {
      if (!this.menuData) return '';
      const strippedMessage = this.stripHtmlTags(this.menuData.fetchedNotificationMessage);
      if (this.menuData.auctionStatus === "upcoming") {
        return `Wickman's auction commences in ${this.countdown.days} days, ${this.countdown.hours} hours, ${this.countdown.minutes} minutes, ${this.countdown.seconds} seconds`;
      } else if (this.menuData.auctionStatus === "live") {
        return `Auction Live! Wickman's auction ends in ${this.countdown.days} days, ${this.countdown.hours} hours, ${this.countdown.minutes} minutes, ${this.countdown.seconds} seconds`;
      } else if (this.menuData.auctionStatus === "closing") {
        return "Auction Closing! Only extended lots remain. Final chance to place your bids";
      } else if (this.menuData.auctionStatus === "closed") {
        return strippedMessage;
      } else {
        return strippedMessage;
      }
    },
  },
  async mounted() {
    this.menuData = await fetchMenuData(); // Fetch menu data on mount
    this.loading = false;
    window.addEventListener('scroll', this.handleScroll);
    window.performSearch = this.performSearch.bind(this);
    
    if (this.menuData) {
      this.startTimer();
    }
  },
  methods: {
      async performSearch() {
      if (!this.SearchBar) {
        this.SearchBar = defineAsyncComponent(() => import('@/components/SearchBar.vue'));
      }
      this.searchVisible = !this.searchVisible;
    },
    closeSearch() {
      this.searchVisible = false;
    },
     startTimer() {
      this.updateCountdown();
      this.timer = setInterval(() => {
        this.updateCountdown();
      }, 1000);
    },
    async updateCountdown() {
      if (!this.menuData) return;
      const now = new Date();
      let targetDate;

      if (this.menuData.auctionStatus === "upcoming") {
        targetDate = new Date(this.menuData.startDate);
      } else if (this.menuData.auctionStatus === "live") {
        targetDate = new Date(this.menuData.endDate);
      } else {
        clearInterval(this.timer);
        return;
      }

      const timeDifference = targetDate - now;
      if (timeDifference <= 0) {
        clearInterval(this.timer);
        this.countdown = { months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
        this.menuData = await fetchMenuData();
        return;
      }

      this.countdown.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      this.countdown.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.countdown.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      this.countdown.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    },
    stripHtmlTags(html) {
      const div = document.createElement("div");
      div.innerHTML = html;
      return div.textContent || div.innerText || "";
    },
    handleScroll() {
      this.isSticky = window.scrollY > 70;
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
    window.removeEventListener('scroll', this.handleScroll);    
  },
};
</script>
