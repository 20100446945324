import apiClient from '@/axios';

export async function fetchMenuData() {
  try {
    const response = await apiClient.get('/menu?new=1');
    const data = response.data;

    return {
  
      fetchedNotificationMessage: data.auction_notice.message,
      auctionStatus: data.auction_notice.status,
      startDate: new Date(data.auction_notice.start_date),
      endDate: new Date(data.auction_notice.end_date),
      };
  } catch (error) {
    console.error("Error fetching menu data:", error);
    return null;
  }
}
