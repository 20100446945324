// src/axios.js
import axios from 'axios';

const baseURL = 'https://admin.wickman.com.au/api/'; // Centralized API base URL
const ImgURL = 'https://admin.wickman.com.au/webimg'; // Centralized API base URL

// Create axios instance
const apiClient = axios.create({
  baseURL, ImgURL,// This is the base URL for API requests
  headers: {
    'Content-Type': 'application/json',
  },
});

// Export baseURL for direct use and apiClient for axios requests
export { baseURL, ImgURL };
export default apiClient;
