import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Ensure this line imports your router
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css'; // Import Toast CSS
import { baseURL } from './axios'; // Import baseURL from axios.js
import './assets/tailwind.css'; // Import Tailwind CSS

const updateMetaTags = (url) => {
  const cleanUrl = url.split('?')[0]; // Remove query parameters

  // Update or create canonical link
  let canonicalTag = document.querySelector('link[rel="canonical"]');
  if (!canonicalTag) {
    canonicalTag = document.createElement('link');
    canonicalTag.setAttribute('rel', 'canonical');
    document.head.appendChild(canonicalTag);
  }
  canonicalTag.setAttribute('href', cleanUrl);

  // Update or create og:url meta tag
  let ogUrlMeta = document.querySelector('meta[property="og:url"]');
  if (!ogUrlMeta) {
    ogUrlMeta = document.createElement('meta');
    ogUrlMeta.setAttribute('property', 'og:url');
    document.head.appendChild(ogUrlMeta);
  }
  ogUrlMeta.setAttribute('content', cleanUrl);
};

const app = createApp(App)
  .use(router) // Ensure to use the router here
  .use(Toast, {
    position: POSITION.TOP_RIGHT,
    timeout: 4000,
    maxToasts: 5, 
    newestOnTop: true,
    icon: true,
    toastClassName: 'custom-toast',
  });

// Make baseURL globally available across your app
app.config.globalProperties.$baseURL = baseURL.replace('/api/', '');

// Watch for route changes and update meta tags dynamically
router.afterEach((to) => {
  setTimeout(() => {
    updateMetaTags(window.location.origin + to.fullPath);
  }, 100); // Ensure it's executed after Vue updates the DOM
});

// Update meta tags on initial load
updateMetaTags(window.location.origin + window.location.pathname);

app.mount('#app');
